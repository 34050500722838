<template>
  <v-sheet class="d-flex flex-column background-plain px-2">
    <v-text-field
      class="ma-2"
      flat
      clearable
      dense
      autofocus
      hide-details
      :label="$t('t.Filter')"
      solo
      v-model="filterText"
      :prepend-inner-icon="$icon('i.Search')"
      v-if="documents.length > 4"
    />
    <grid
      animation-name="slide-x"
      item-min-width="min-content"
      grid-gap=0
      :repeat="Math.floor(Math.sqrt(matchingDocuments.length))"
    >
      <document-tab
        :data-cy="'document-tab-'+document.type"
        v-for="document in matchingDocuments"
        :key="document.type"
        :active="document.type === activeDocument"
        :document="document"
        :search="search"
        @click="$emit('select-document', document)"
        v-on="$listeners"
      />
    </grid>
  </v-sheet>
</template>

<script>
import Search from '@/pages/search/controllers'
export default {
  components: {
    DocumentTab: () => import('./document-tab.vue'),
    Grid: () => import('@/components/grid')
  },
  computed: {
    matchingDocuments () {
      return this.documents.filter(d => d.name.toLowerCase().includes((this.filterText ?? ''))).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  data () {
    return {
      filterText: ''
    }
  },
  methods: {
    matchFilter (item) {
      return item.name.toLowerCase().includes((this.filterText ?? '').toLowerCase())
    }
  },
  props: {
    activeDocument: String,
    documents: Array,
    search: Search
  }
}
</script>
